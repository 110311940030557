import { duck } from '@/plugins/utils'

export default {
  data: function () {
    return {
      formInitalState: '',
      formInitalString: ''
    }
  },
  methods: {
    getValue (element) {
      let ret = ''
      switch (element.type) {
        case 'checkbox':
        case 'radio':
          ret = element.checked
          break
        case 'number':
        case 'hidden':
        case 'password':
        case 'date':
        case 'text':
        case 'textarea':
          ret = element.value
          break
        case 'select-one':
        case 'select-multiple': {
          for (var j = 0; j < element.options.length; j++) {
            ret += element.options[j].selected
          }
          break
        }
        case 'file': {
          if (element.files !== undefined && element.files.length > 0) {
            element.files.forEach(file => {
              ret = file.lastModified
            })
          }
          break
        }
      }
      return ret
    },
    setFormInitalValue (form) {
      if (form && form.$el) {
        const finalString = [...form.$el.elements].reduce((acc, currentInput) => {
          return acc + this.getValue(currentInput)
        }, undefined)
        this.formInitalString = finalString
        const crypto = require('crypto')
        this.formInitalState = crypto.createHash('md5').update(finalString).digest('hex')
      }
    },
    isFormDirty (form) {
      if (form && form.$el) {
        const finalString = [...form.$el.elements].reduce((acc, currentInput) => {
          return acc + this.getValue(currentInput)
        }, undefined)

        const crypto = require('crypto')
        const md5Str = crypto.createHash('md5').update(finalString).digest('hex')
        return md5Str !== this.formInitalState
      }
    },

    onFormInputChange (prop) {
      if (this.register[prop].id && this.register[prop].id === this.initialRegister[prop]) {
        this.isDirty = false
      } else {
        if (!duck(String(this.register[prop]), String(this.initialRegister[prop]))) {
          this.isDirty = true
        } else {
          this.isDirty = false
        }
      }
    }
  }
}
