<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    max-width="570px"
    style="z-index: 6"
  >
    <v-form ref="editReceiptSettingsForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-dialog-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Postavke računa prodajnog mjesta</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-textarea
                :label="$t('$vuetify.companies.notePOS')"
                v-model="notePOS"
                rows="3"
                outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-textarea
                :label="$t('$vuetify.companies.noteA4')"
                v-model="noteA4"
                rows="3"
                outlined
              >
              </v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            {{ $t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { clone } from '@/plugins/utils'
import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import requestChecker from '@/mixins/requestChecker'
import state from '@/state'

export default {
  inject: ['showMsgBox'],
  mixins: [requestChecker],
  data: function () {
    return {
      visible: false,
      submitting: false,
      location: {},
      notePOS: '',
      noteA4: '',
      initNotePos: '',
      initNoteA4: '',
      rules: {
        req: rules.req()
      }
    }
  },
  methods: {
    open (location) {
      this.visible = true
      this.location = clone(location)
      this.notePOS = this.location.note_pos
      this.noteA4 = this.location.note_a4
      this.initNotePos = this.notePOS
      this.initNoteA4 = this.noteA4

      const params = {
        note_pos: this.notePOS ? this.notePOS : '',
        note_a4: this.noteA4 ? this.noteA4 : ''
      }

      this.registerRequestParams(params)
    },
    close () {
      this.visible = false
    },
    submit () {
      if (
        this.notePOS === this.initNotePos &&
        this.noteA4 === this.initNoteA4
      ) {
        this.showMsgBox({ text: this.$t('$vuetify.errors.noChangesInEntities'), actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      if (!this.$refs.editReceiptSettingsForm.validate()) return

      if (this.location.note_pos === this.notePOS && this.location.note_a4 === this.noteA4) {
        this.visible = false
        return
      }

      try {
        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'update',
            entity: 'location',
            resource_id: this.location.id,
            params: {
              company_id: state.getCurrentCompany().id,
              note_pos: this.notePOS,
              note_a4: this.noteA4
            }
          }
        }

        if (this.notePOS === undefined || this.notePOS === null) {
          payload.action.params.note_pos = ''
        }

        if (this.noteA4 === undefined || this.noteA4 === null) {
          payload.action.params.note_a4 = ''
        }

        // const cleanParams = this.getCleanParams(payload.action.params)
        // Object.keys(cleanParams).forEach((key) => {
        //   delete payload.action.params[key]
        // })

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'location',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(() => {
            this.$emit('editSuccess', this.location)
            this.close()
          })
          .catch(function (error) {
            // console.error('Error writing document: ', error)
            this.showMsgBox({
              text: error || 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })
          .finally(() => {
            this.submitting = false
          })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
