<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    max-width="450px"
    style="z-index: 6"
  >
    <v-form ref="editTaxesForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-dialog-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Postavke poreza</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-checkbox
                outlined
                dense
                class="mt-0"
                width="20%"
                v-model="hasPnp"
                label="Porez na potrošnju"
              >
              </v-checkbox>
            </v-col>

            <v-col cols="6">
              <v-text-field
                dense
                outlined
                :disabled="!hasPnp"
                label="Poreza na potrošnju"
                hint="Postotak samo broj"
                persistent-hint
                @keypress="checkNumber($event)"
                v-model="pnp"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row class="pl-5">
            <p style="font-size: 16px">
              Promjena poreza na potrošnju utječe na cijenu artikala.
              <br />Odaberite želite li promijeniti ukupnu cijenu artikla ili
              osnovicu artikla
            </p>
            <v-radio-group row v-model="pnpChangeType">
              <v-radio label="Promijeni cijenu" value="price" />
              <v-radio label="Promijeni osnovnicu" value="base" />
            </v-radio-group>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="pnpSame"
            type="submit"
          >
            {{ $t("$vuetify.save") }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { clone } from '@/plugins/utils'
import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import requestChecker from '@/mixins/requestChecker'
import state from '@/state'

export default {
  inject: ['showMsgBox'],
  mixins: [requestChecker],
  data: function () {
    return {
      visible: false,
      submitting: false,
      location: {},
      hasPnp: false,
      pnp: 0,
      pnpChangeType: undefined,
      initialData: {},
      rules: {
        req: rules.req()
      }
    }
  },
  computed: {
    pnpSame () {
      if (this.initialHasPnp === this.hasPnp && this.initialPnp === this.pnp) {
        return true
      }

      return false
    }
  },
  methods: {
    checkNumber (ev) {
      // change this with a custom directive  this accepts custom regex and checks input field
      const maskRe = /^([0-9]+)|([,])$/
      // const maskRe = /^([0-9]+)$/
      if (([','].some(sep => this.$attrs?.value?.toString()?.includes(sep)) && [','].some(sep => ev.key === sep)) || !maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    open (location) {
      this.visible = true
      this.location = clone(location)
      this.hasPnp = this.location.has_pnp ? this.location.has_pnp : false
      var pnp = this.location.pnp ? this.location.pnp / 100 : 0
      pnp = pnp.toFixed(2).replace('.', ',')
      this.pnp = pnp

      this.initialPnp = clone(this.pnp)
      this.initialHasPnp = clone(this.hasPnp)

      if (location.pnp_change_type) {
        this.pnpChangeType = location.pnp_change_type
      } else {
        this.pnpChangeType = 'base'
      }

      const params = {
        has_pnp: this.hasPnp ? this.hasPnp : false,
        pnp: this.pnp ? this.pnp : 0
      }

      this.initialData.hasPnp = clone(this.hasPnp)
      this.initialData.pnp = clone(this.pnp)
      this.initialData.pnpChangeType = clone(this.pnpChangeType)

      this.registerRequestParams(params)
    },
    close () {
      this.visible = false
    },
    submit () {
      if (
        this.hasPnp === this.initialData.hasPnp &&
        this.pnp === this.initialData.pnp &&
        this.pnpChangeType === this.initialData.pnpChangeType
      ) {
        this.showMsgBox({ text: this.$t('$vuetify.errors.noChangesInEntities'), actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      if (!this.$refs.editTaxesForm.validate()) return

      if (this.location.has_pnp === this.hasPnp && this.location.pnp === this.pnp) {
        this.visible = false
        return
      }

      try {
        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'update',
            entity: 'location',
            resource_id: this.location.id,
            params: {
              company_id: state.getCurrentCompany().id,
              has_pnp: this.hasPnp,
              pnp: this.pnp !== '' ? parseInt(parseFloat(this.pnp.replace(',', '.')) * 100) : 0
            }
          }
        }

        if (this.hasPnp === undefined || this.hasPnp === null) {
          payload.action.params.has_pnp = false
        }

        if (this.pnp === undefined || this.pnp === null) {
          payload.action.params.pnp = 0
        }

        if (this.pnpChangeType) {
          payload.action.params.pnp_change_type = this.pnpChangeType
        }

        if (payload.action.params.has_pnp === false) {
          payload.action.params.pnp = 0
        }

        // const cleanParams = this.getCleanParams(payload.action.params)
        // Object.keys(cleanParams).forEach((key) => {
        //   delete payload.action.params[key]
        // })

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'location',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(() => {
            this.$emit('editSuccess', this.location)
            this.close()
          })
          .catch(function (error) {
            // console.error('Error writing document: ', error)
            this.showMsgBox({
              text: error || 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
          })
          .finally(() => {
            this.submitting = false
          })
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
