<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    max-width="450"
    style="z-index: 6"
  >
    <v-form ref="addSellingPointForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Prodajno mjesto</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                autofocus
                dense
                outlined
                label="Naziv prodajnog mjesta"
                v-model="pointOfSale.name"
                :rules="[rules.req]"
                id="name"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                label="Oznaka prodajnog mjesta"
                v-model="pointOfSale.location_id"
                autocomplete="off"
                persistent-hint
                hint="Samo brojevi i slova"
                @keypress="checkInput($event)"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                dense
                outlined
                label="Adresa"
                v-model="pointOfSale.address"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                label="Grad"
                v-model="pointOfSale.city"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="8" md="6">
              <v-text-field
                dense
                outlined
                label="Poštanski broj"
                v-model="pointOfSale.zip_code"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import { clone, duck } from '@/plugins/utils'
import { v4 as uuidv4 } from 'uuid'
import state from '@/state'
import countries from '@/mixins/countries'
import requestChecker from '@/mixins/requestChecker'

export default {
  name: 'AddSellingPoint',
  data: () => ({
    visible: false,
    submitting: false,
    pointOfSale: {},
    initialPointOfSale: {},
    defultPointOfSale: {},
    error: {
      show: false,
      message: undefined
    },
    rules: {
      req: rules.req(),
      eq: rules.eq
    }
  }),
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [countries, requestChecker],
  mounted () {
    document.onkeydown = this.handleKeyEvents
  },
  methods: {
    checkInput (ev) {
      // change this with a custom directive  that accepts custom regex and checks input field
      const maskRe = /[A-Za-z0-9]/
      if (!maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    handleKeyEvents (e) {
      e = e || window.event
      if (e.keyCode === 9) {
        if (document.activeElement.id.includes('lastElement')) {
          e.preventDefault()
          document.getElementById('name').focus()
        }
      }
    },
    open (pointOfSale) {
      this.visible = true
      this.submitting = false

      this.defultPointOfSale = Object.assign(
        this.defultPointOfSale,
        pointOfSale
      )
      this.pointOfSale = Object.assign(this.pointOfSale, pointOfSale)
      this.initialPointOfSale = Object.assign(this.initialPointOfSale, pointOfSale)

      this.registerRequestParams(this.pointOfSale)
    },
    close () {
      this.$emit('close')
      this.visible = false
      // this.$refs.addSellingPointForm.reset()
    },
    submit () {
      if (duck(this.pointOfSale, this.initialPointOfSale)) {
        this.showMsgBox({ text: this.$t('$vuetify.errors.noChangesInEntities'), actions: ['ok'], cancelBtnText: 'OK', color: 'error' })
        return
      }

      const that = this
      if (!this.$refs.addSellingPointForm.validate()) return
      this.submitting = true

      if (duck(this.pointOfSale, this.defultPointOfSale)) {
        return
      }

      try {
        const reqId = uuidv4()

        const payload = {
          action: {
            operation: 'update',
            entity: 'location',
            resource_id: this.pointOfSale.id,
            params: {
              ...this.pointOfSale,
              ...{ company_id: state.getCurrentCompany().id }
            }
          }
        }

        const fullpayload = clone(payload.action.params)

        const cleanParams = this.getCleanParams(this.pointOfSale)
        Object.keys(cleanParams).forEach((key) => {
          if (key !== 'company_id') {
            delete payload.action.params[key]
          }
        })

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'location',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function (doc) {
            that.visible = false
            that.$emit('editSuccess', fullpayload)
          })
          .catch(function (error) {
            // console.error('Error writing document: ', error)
            that.showMsgBox({
              text: error || 'Internal error',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.submitting = false
          })
          .finally(() => {})
      } catch (err) {
        that.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        that.submitting = false
      }
    }
  }
}
</script>
