<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    style="z-index: 6"
    max-width="450"
  >
    <v-form ref="addSellingPointForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Prodajno mjesto</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                autofocus
                dense
                hide-details
                outlined
                label="Naziv prodajnog mjesta"
                v-model="locationName"
                :rules="[rules.req]"
                id="name"
              ></v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                label="Oznaka prodajnog mjesta"
                autocomplete="off"
                persistent-hint
                hint="Samo brojevi i slova"
                @keypress="checkInput($event)"
                v-model="locationMark"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-text-field
                dense
                outlined
                label="Adresa"
                v-model="locationAddress"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
            <v-col>
              <v-text-field
                dense
                outlined
                label="Grad"
                v-model="locationCity"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="6" sm="8" md="6">
              <v-text-field
                dense
                outlined
                label="Poštanski broj"
                v-model="locationZipCode"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
            <v-col v-if="whModule" cols="6">
              <v-checkbox
                outlined
                dense
                class="mt-0"
                width="20%"
                v-model="hasPnp"
                label="Porez na potrošnju"
              >
              </v-checkbox>
            </v-col>
          </v-row>
          <v-row v-if="whModule && hasPnp">
            <v-col cols="6">
              <v-text-field
                dense
                outlined
                label="Poreza na potrošnju"
                hint="Postotak samo broj"
                persistent-hint
                @keypress="checkNumber($event)"
                v-model="pnp"
                :rules="[rules.req]"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="d-flex justify-center pb-5">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
            id="lastElement"
          >
            Dodaj prodajno mjesto
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import rules from '@/plugins/rules'
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import state from '@/state'
import countries from '@/mixins/countries'
import formUtils from '@/mixins/formUtils'
export default {
  name: 'AddSellingPoint',
  data: () => ({
    visible: false,
    submitting: false,
    locationName: undefined,
    locationMark: undefined,
    locationAddress: undefined,
    locationCity: undefined,
    locationZipCode: undefined,
    country: undefined,
    company: {},
    locations: [],
    error: {
      show: false,
      message: undefined
    },
    rules: {
      req: rules.req(),
      eq: rules.eq
    },
    hasPnp: false,
    pnp: undefined
  }),
  inject: ['showMsgBox', 'showLoader', 'hideLoader', 'confirm', 'confirmClose'],
  mixins: [countries, formUtils],
  mounted () {
    document.onkeydown = this.handleKeyEvents
    this.setDefaults()
  },
  computed: {
    whModule () {
      if (state.getCurrentCompany() &&
        state.getCurrentCompany().warehouse &&
        state.getCurrentCompany().warehouse === 'module') {
        return true
      } else {
        return false
      }
    }
  },
  methods: {
    checkInput (ev) {
      // change this with a custom directive  this accepts custom regex and checks input field
      const maskRe = /[A-Za-z0-9]/
      if (!maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    checkNumber (ev) {
      const maskRe = /^([0-9]+)|([,])$/
      // const maskRe = /^([0-9]+)$/
      if (([','].some(sep => this.$attrs?.value?.toString()?.includes(sep)) && [','].some(sep => ev.key === sep)) || !maskRe.test(ev.key)) {
        ev.preventDefault()
        return false
      }
    },
    setDefaults () {
      this.country = this.countries.find((c) => c.key === 'HR')
    },
    handleKeyEvents (e) {
      e = e || window.event
      if (e.keyCode === 9) {
        // stops the default behavior of moving focus to the back page
        // document.getElementById('name').focus()
        if (document.activeElement.id.includes('lastElement')) {
          e.preventDefault()
          document.getElementById('name').focus()
        }
      }
    },
    open () {
      this.getLocations()
      this.visible = true
      this.submitting = false

      this.$nextTick(() => {
        this.setFormInitalValue(this.$refs.addSellingPointForm)
      })
    },
    getLocations () {
      this.showLocationSelector = false

      df.collection('locations')
        .where('company_id', '==', state.getCurrentCompany().id)
        .get()
        .then((documentSnapshots) => {
          if (documentSnapshots.docs && !documentSnapshots.docs.empty) {
            documentSnapshots.docs.forEach((location) => {
              const loc = location.data()
              this.locations.push(loc)
            })
          }
        })
    },
    close () {
      this.locations = []
      this.$emit('close')
      if (this.isFormDirty(this.$refs.addSellingPointForm)) {
        this.confirm({
          title: this.$t('$vuetify.formDetails.leaveFormTitle'),
          message: this.$t('$vuetify.formDetails.leaveFormText'),
          options: {
            toolbar: true,
            width: 410,
            confirmText: this.$t('$vuetify.formDetails.leave'),
            cancelText: this.$t('$vuetify.formDetails.stay')
          }
        }).then((resp) => {
          if (resp) {
            this.visible = false
            this.$refs.addSellingPointForm.reset()
            this.confirmClose()
          }
        })
      } else {
        this.visible = false
        this.$refs.addSellingPointForm.reset()
      }
    },
    validateLocationMark () {
      let validated = true
      this.locations.forEach(loc => {
        if (loc.location_id === this.locationMark) {
          this.showMsgBox({
            text: 'Oznaka prodajnog mjesta se već koristi, molimo unesite drugu oznaku.',
            actions: ['cancel'],
            cancelBtnText: this.$t('$vuetify.close'),
            color: 'error'
          })

          this.locationMark = ''
          validated = false
        }
      })
      return validated
    },
    submit () {
      if (!this.$refs.addSellingPointForm.validate()) return
      if (!this.validateLocationMark()) return

      this.submitting = true
      try {
        const reqId = uuidv4()
        const payload = {
          action: {
            operation: 'set',
            entity: 'location',
            params: {
              name: this.locationName,
              location_id: this.locationMark,
              address: this.locationAddress,
              city: this.locationCity,
              zip_code: this.locationZipCode,
              company_id: state.getCurrentCompany().id
            }
          }
        }

        if (this.hasPnp === true && this.pnp !== '') {
          payload.action.params.has_pnp = true
          payload.action.params.pnp = this.pnp !== '' ? parseInt(parseFloat(this.pnp.replace(',', '.')) * 100) : 0
        }

        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'location',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then((doc) => {
            this.visible = false
            this.$refs.addSellingPointForm.reset()
            this.setDefaults()
            this.$emit('success', payload.action.params)
          })
          .catch(function (error) {
            // console.error('Error writing document: ', error)
            this.showMsgBox({
              text: error || 'Internal error',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            this.submitting = false
          })
          .finally(() => {})
      } catch (err) {
        this.showMsgBox({
          text: err || 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.submitting = false
      }
    }
  }
}
</script>
