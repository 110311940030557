<template >
  <v-container
    fluid
    class="d-flex align-center justify-center fill-height flex-wrap"
  >
    <v-btn
      style="z-index: 4; bottom: 60px"
      rounded
      fixed
      bottom
      right
      color="primary"
      @click="openAddPointOfSaleForm"
      v-if="$vuetify.breakpoint.smAndDown"
    >
      <v-icon>mdi-plus</v-icon>
      <span>{{ $t(`$vuetify.company.addPointOfSaleBtn`) }}</span>
    </v-btn>
    <v-container
      v-if="!pointsOfSale || pointsOfSale.length === 0"
      class="d-flex fill-height justify-center"
      style="height: 800px"
    >
      <div>
        Još nemate niti jedno dodano prodajno mjesto.
        <a href="" @click.prevent="openAddPointOfSaleForm"
          >Dodaj prodajno mjesto</a
        >
      </div>
    </v-container>
    <v-container
      v-else
      fluid
      class="d-flex justify-center align-start flex-wrap ma-0 pa-0 fill-height"
    >
      <v-row>
        <v-spacer></v-spacer>
        <Search-box
          :emitDestination="searchEmitDestination"
          searchPlaceholder="Pretraži prodajna mj."
        ></Search-box>
      </v-row>
      <v-row
        class="justify-center fill-height ma-0 pa-0"
        style="position: absolute"
      >
        <v-card
          class="align-center ma-5"
          v-for="(sPoint, i) in presentPSales"
          :key="i"
          hover
          height="300"
          width="250"
          @click.stop="openPointOfSale(sPoint)"
          @keypress.enter.stop="openPointOfSale(sPoint)"
          :ref="`pSale${i}`"
        >
          <v-img
            height="104"
            width="250"
            src="@/assets/selling_point_cover.jpg"
          />
          <div class="mr-4 ml-4">
            <div class="mt-2 mb-2">
              <h3 class="one-liner-text">{{ sPoint.name }}</h3>
              <p class="grey--text mb-1 selling-point-text">
                {{ sPoint.address }}, {{ sPoint.city }}
              </p>
            </div>
            <div class="mt-2">
              <div class="subtitle-1 selling-point-text">
                Oznaka mjesta: {{ sPoint.location_id }}
              </div>
              <div class="subtitle-1 one-liner-text">
                Status: {{ sPoint.status || 0 }}
              </div>
            </div>
          </div>
          <v-divider class="mt-4"></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-menu offset-y nudge-left="145" v-if="!hideMenu">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on" @keypress.enter.stop="">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>
              <v-list dense>
                <v-list-item
                  v-for="(item, index) in locationOptions"
                  :key="index"
                  style="cursor: pointer"
                  @click="`${item.action(sPoint)}`"
                  @keypress.enter.prevent="`${item.action(sPoint)}`"
                  :disabled="sPoint.status === 'PROCESSING'"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
    <add-point-of-sale
      ref="pointOfSaleForm"
      @success="refreshPoints"
    ></add-point-of-sale>
    <edit-point-of-sale
      ref="editPointOfSaleForm"
      @editSuccess="refreshEditPoint"
    ></edit-point-of-sale>
    <edit-spoint-receipt-settings
      ref="editPointOfSaleReceiptSettings"
      @editSuccess="refreshEditPoint"
    ></edit-spoint-receipt-settings>
    <edit-point-of-sale-taxes
      ref="editPointOfSaleTaxes"
      @editSuccess="refreshEditPoint"
    ></edit-point-of-sale-taxes>
  </v-container>
</template>
<script>

import { auth, df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import AddPointOfSale from '@/modules/company/components/AddPointOfSale'
import EditPointOfSale from '@/modules/company/components/EditPointOfSale'
import EditSpointReceiptSettings from '@/modules/company/components/EditPointOfSaleReceiptSettings'
import state from '@/state'
import EventBus from '@/plugins/event-bus'
import applicationSettings from '@/mixins/applicationSettings'
import EditPointOfSaleTaxes from '@/modules/company/components/EditPointOfSaleTaxes'

export default {
  props: ['company'],
  components: {
    AddPointOfSale,
    EditPointOfSale,
    EditSpointReceiptSettings,
    EditPointOfSaleTaxes
  },
  data: vm => ({
    reveal: false,
    sellingPoint: { title: undefined, text: undefined },
    pointsOfSale: [],
    presentPSales: [],
    listeners: [],
    hideMenu: false,
    locationOptions: [
      { title: 'Postavke prodajnog mjesta', action: vm.openEditPointOfSaleForm },
      { title: 'Postavke računa', action: vm.editReceiptSettings },
      { title: 'Konfiguracija printera', action: vm.openPrinterConfiguration },
      { title: 'Konfiguracija ustrojstvenih jedinica', action: vm.openOrganizationalConfiguration }
    ],
    searchEmitDestination: 'pointOfSaleSearch'
  }),
  mixins: [applicationSettings],
  computed: {
    search () {
      return state.filter
    },
    whModule () {
      if (state.getCurrentCompany() &&
        state.getCurrentCompany().warehouse &&
        state.getCurrentCompany().warehouse === 'module') {
        return true
      } else {
        return false
      }
    }
  },
  watch: {
    pointsOfSale: {
      handler (nv, ov) {
        if (nv !== ov) {
          let presentPSales = []
          if (this.search && this.search !== '') {
            this.presentCompanies = nv.filter(company => company.name.toLowerCase().includes(this.search.toLowerCase()))
          } else {
            presentPSales = nv
          }
          this.presentPSales = clone(presentPSales.sort((a, b) => a.name.localeCompare(b.name)))
        }
      },
      deep: true,
      immediate: true
    }
    // STARI SEARCH, VISE SE NE KORISTI
    // search (val) {
    //   let presentPSales = []
    //   if (val !== '') {
    //     presentPSales = this.pointsOfSale.filter(pSale => pSale.name.toLowerCase().includes(val.toLowerCase()))
    //   } else {
    //     presentPSales = this.pointsOfSale
    //   }
    //   this.presentPSales = clone(presentPSales.sort((a, b) => a.name.localeCompare(b.name)))
    // }
  },
  mounted () {
    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      this.searchAll(searchObject.searchString)
    })
    const user = state.getUser()

    if (!user.roles.ADMIN && !user.roles.SUPERADMIN) {
      this.hideMenu = true
    }
    try {
      this.$refs.pSale0[0].$el.focus()
    } catch {
      setTimeout(() => {
        if (this.$refs.pSale0 && this.$refs.pSale0[0]) {
          this.$refs.pSale0[0].$el.focus()
        }
      }, 500)
    }
    EventBus.$on('add-pSale', () => {
      if (this.$refs.pointOfSaleForm) {
        this.$refs.pointOfSaleForm.open(state.getCurrentCompany())
      }
    })

    if (this.whModule === true) {
      this.locationOptions.push({ title: 'Postavke poreza', action: this.openEditPointOfSaleTaxes }
      )
    }
  },
  created () {
    const docRef = df.doc(`user_locations/${auth.currentUser.uid}.${state.getCurrentCompany().id}`)
    const listener = docRef.onSnapshot((doc) => {
      if (doc && doc.data() && doc.data().locations && Object.keys(doc.data().locations).length > 0) {
        const data = doc.data()
        if (data?.locations) {
          this.pointsOfSale = Object.keys(data.locations)
            .map(key => {
              return data.locations[key]
            })
            .filter(location => location?.resource_status === 'ACTIVE')
            .sort((location1, location2) => {
              if (location1.id < location2.id) return -1
              if (location1.id > location2.id) return 1
            })
        }
      }
    })
    this.listeners.push(listener)
  },
  methods: {
    searchAll (val) {
      let presentPSales = []
      if (val !== '') {
        presentPSales = this.pointsOfSale.filter(pSale => pSale.name.toLowerCase().includes(val.toLowerCase()))
      } else {
        presentPSales = this.pointsOfSale
      }
      this.presentPSales = clone(presentPSales.sort((a, b) => a.name.localeCompare(b.name)))
    },
    openEditPointOfSaleTaxes (sPoint) {
      this.$refs.editPointOfSaleTaxes.open(sPoint)
    },
    openAddPointOfSaleForm () {
      this.$refs.pointOfSaleForm.open()
    },
    openEditPointOfSaleForm (sPoint) {
      this.$refs.editPointOfSaleForm.open(sPoint)
    },
    editReceiptSettings (sPoint) {
      this.$refs.editPointOfSaleReceiptSettings.open(sPoint)
    },
    openOrganizationalConfiguration (sPoint) {
      this.$router.push({
        name: 'pointOfSale.organizationalUnitConfiguration',
        params: { companyId: state.getCurrentCompany().id, locationId: sPoint.id }
      })
    },
    openPrinterConfiguration (sPoint) {
      this.$router.push({
        name: 'pointOfSale.printerConfiguration',
        params: { companyId: state.getCurrentCompany().id, locationId: sPoint.id }
      })
    },
    openPointOfSale (sPoint) {
      if (sPoint.status !== 'OK') {
        return
      }
      state.setPointOfSale(sPoint)
      state.setAppTitle(sPoint.name)

      this.$router.push({
        name: 'pointOfSale',
        params: { companyId: state.getCurrentCompany().id, locationId: sPoint.id }
      })
    },
    refreshPoints (sPoint) {
      const itemToAdd = {
        name: sPoint.name,
        location_id: sPoint.location_id,
        cash_register_count: 0,
        user_count: 0,
        city: sPoint.city,
        address: sPoint.address,
        status: 'PROCESSING'
      }
      this.pointsOfSale.push(itemToAdd)
      this.presentPSales.push(itemToAdd)
      this.presentPSales = this.presentPSales.sort((a, b) => a.name.localeCompare(b.name))
    },
    refreshEditPoint (sPoint) {
      this.presentPSales = this.presentPSales.map(point => {
        if (point.id === sPoint.id) {
          point.name = sPoint.name
          point.address = sPoint.address
          point.city = sPoint.city
          point.location_id = sPoint.location_id
          point.status = 'PROCESSING'
        }
        return point
      })
    }
  }
}
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}

.one-liner-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: block;
}

.selling-point-text {
  overflow: hidden;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.selling-point-title-text {
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
}

.add-sPoint-plus {
  height: calc(100% - 108px);
}
</style>
